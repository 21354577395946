import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>   
               האתר בתחזוקה
       
               </p>
        <a
    
        >
        עמכם הסליחה , נחזור לפעילות בהקדם האפשרי
        </a>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4942463590341754"
     crossorigin="anonymous"></script>
      </header>

    </div>
  );
}

export default App;
